import React from 'react'
import Footer from '../../custom-components/footer'



export default ()=>{
    return(
    <div className="text-gray-700">

        <Footer />
    </div>)
}

