import React from 'react'
import { useStaticQuery, Link, graphql } from "gatsby"
import Logo from '../assets/logo.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons"





const Footer =  ()=>{
    const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          copyrightYear
          social{
              name
              url
              
          }
        }
      }
    }
  `);

const [socialLinks, year, name] = [query.site.siteMetadata.social, query.site.siteMetadata.copyrightYear, query.site.siteMetadata.author];
    return(
        <footer className="min-w-full bg-gray-800 md:mt-16">
          
        

          <div className="flex flex-col flex-col-reverse md:flex-row  flex-wrap justify-around py-1 items-center leading-loose">
          

          
          <div className="p-1">
          <a href="https://www.facebook.com/geekynutpage" className="px-2" >
            <FontAwesomeIcon icon={faFacebook} className="text-orange-500 fa-2x hover:text-blue-100"/>
          </a>
          <a href="https://twitter.com/geekynut" className="px-2">
            <FontAwesomeIcon icon={faTwitter}  className="text-orange-500 fa-2x hover:text-blue-100"/>
          </a>
          <a href="https://github.com/geekynut" className="px-2">
            <FontAwesomeIcon icon={faGithub}  className="text-orange-500  hover:text-blue-100 fa-2x"/>
          </a>
                                
          </div>
         <div className="p-1 text-center">
           <Link to="/" className="bg-white rounded-full md:-mt-12 md:hover:bg-blue-100 inline-block p-3 md:border-2 md:border-gray-800 ">

              <Logo className="h-16 w-16 "/>
           </Link>
           <p className="block text-white text-sm">
              &copy; copyright {name} {year}  
              </p>  
         </div>  
          
          <div className="text-center p-1 md:text-left text-sm">
                    
            <Link to="/cookies" className="block hover:text-blue-400 text-gray-100" >Cookies Policy</Link>
            <Link to="/terms" className="block hover:text-blue-400 text-gray-100 ">Terms and Condition</Link>
            
              
               </div>
                    
         </div>

        </footer>
    )
}




export default Footer